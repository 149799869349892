$nkr-theme-colors: (
  'primary-0': #000,
  'primary-10': #001e2c,
  'primary-20': #003549,
  'primary-30': #004d68,
  'primary-40': #006689,
  'primary-50': #0080ab,
  'primary-60': #209bcc,
  'primary-70': #4ab6e8,
  'primary-80': #78d1ff,
  'primary-90': #c3e8ff,
  'primary-95': #e2f3ff,
  'primary-99': #fbfcff,
  'primary-100': #fff,
  'secondary-0': #000,
  'secondary-10': #110068,
  'secondary-20': #2000a4,
  'secondary-30': #392bbc,
  'secondary-40': #5249d4,
  'secondary-50': #6c64ee,
  'secondary-60': #8781ff,
  'secondary-70': #a5a1ff,
  'secondary-80': #c4c0ff,
  'secondary-90': #e3dfff,
  'secondary-95': #f2eeff,
  'secondary-99': #fffbff,
  'secondary-100': #fff,
  'tertiary-0': #000,
  'tertiary-10': #410002,
  'tertiary-20': #690006,
  'tertiary-30': #93000c,
  'tertiary-40': #be0c17,
  'tertiary-50': #e22f2d,
  'tertiary-60': #ff544b,
  'tertiary-70': #ff897e,
  'tertiary-80': #ffb4ab,
  'tertiary-90': #ffdad6,
  'tertiary-95': #ffedea,
  'tertiary-99': #fffbff,
  'tertiary-100': #fff,
  'error-0': #000,
  'error-10': #410001,
  'error-20': #680003,
  'error-30': #930006,
  'error-40': #ba1b1b,
  'error-50': #dd3730,
  'error-60': #ff5449,
  'error-70': #ff897a,
  'error-80': #ffb4a9,
  'error-90': #ffdad4,
  'error-95': #ffede9,
  'error-99': #fcfcfc,
  'error-100': #fff,
  'neutral-0': #000,
  'neutral-10': #191c1e,
  'neutral-20': #2e3133,
  'neutral-30': #454749,
  'neutral-40': #5c5f61,
  'neutral-50': #757779,
  'neutral-60': #8f9193,
  'neutral-70': #aaabae,
  'neutral-80': #c5c6c9,
  'neutral-90': #e1e2e5,
  'neutral-95': #f0f1f4,
  'neutral-99': #fbfcfe,
  'neutral-100': #fff,
  'neutral-variant-0': #000,
  'neutral-variant-10': #151c21,
  'neutral-variant-20': #2a3136,
  'neutral-variant-30': #41484d,
  'neutral-variant-40': #585f64,
  'neutral-variant-50': #71787d,
  'neutral-variant-60': #8a9297,
  'neutral-variant-70': #a5acb2,
  'neutral-variant-80': #c0c7cd,
  'neutral-variant-90': #dce3e9,
  'neutral-variant-95': #ebf2f8,
  'neutral-variant-99': #fbfcff,
  'neutral-variant-100': #fff,
);

@function c($color-name: null) {
  @return map-get($nkr-theme-colors, $color-name);
}

// Usage: eg. color: c(primary-40);

$on-primary: c(primary-100);
$on-secondary: c(secondary-100);
$on-tertiary: c(tertiary-100);
$on-error: c(error-100);

$primary-container: c(primary-90);
$on-primary-container: c(primary-10);
$secondary-container: c(secondary-90);
$on-secondary-container: c(secondary-10);
$tertiary-container: c(tertiary-90);
$on-tertiary-container: c(tertiary-10);
$error-container: c(error-90);
$on-error-container: c(error-10);

$background: c(neutral-99);
$on-background: c(neutral-10);
$surface: c(neutral-99);
$on-surface: c(neutral-10);
$surface-variant: c(neutral-variant-90);
$on-surface-variant: c(neutral-variant-30);
$outline: c(neutral-90);

$advertorial: #22abb2;
$advertorial-background: #fff;
$heimat: #4ab32b;
$heimat-background: #f2f5f1;
