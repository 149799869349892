.fp-header {
  &-logo {
    svg {
      max-width: 113px;
    }
  }
}

.fp-header-navigation_container {
  ul {
    gap: 10px;
    justify-content: space-between;

    a {
      font-size: 15px;
    }
  }
}
