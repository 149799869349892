// Product Variables
@import '../../themes/_shared/variables';

// Overrides
$body-text: $on-background;
$font-family: $font-primary;

$spacer: 1rem;

$container-width: 1030px;
$container-width-small: 1030px;
$heading-text-color: c(neutral-10);
$paragraph-text-color: $on-background;
$main-brand-color: c(primary-40);
$secondary-color: c(secondary-40);
$border-color: #c5c6c9;
$outline-color: #ff8f00;
$primary-color: c(primary-40);

$heimat-arrow: 'data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" fill="%234ab32b" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>';
$primary-color-arrow: 'data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23006689" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>';
$black-arrow: 'data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" fill="%23191c1e" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>';

//$gray-900: c(neutral-20);
//$fp-gray-100: c(neutral-20);

$header-height: 50px;
$header-height-sm: 50px;

$header-logo-height-xl: 32px;
$header-logo-height-lg: 26px;

// Broadsheet Colors
$body-text: $on-background;
$header-text: $on-background;
$details-color: $main-brand-color;

// SVE Variables

body * {
  color: $body-text;
}

a {
  color: c(primary-40);
}

.article_body a:not(.sve-teaser_small, .sve-teaser_large, .sve-teaser_medium) {
  text-decoration: underline;
  color: #006f94;
}

hr {
  height: 1px;
  background-color: $outline;
  border: 0;
  margin: 2rem 0;
}

//Box Shadows
$box-shadow_one: 0 2px 3px rgba(0, 0, 0, 0.15);

$box-shadow-sidemenu: $box-shadow_one;
