// This file is a reference point for all the VARIABLES
// Each variable is commented and has basic instructions
// It is imported in the index.scss file

// This is a basic spacer unit variable, modify it to fit your design
$spacer: 4px;

// This is a container variable, modify it to fit your design
$container-width: 1200px;
$side-menu-width: 350px;

// Breakpoint variables
$mobile: 785px;
$tablet: 1171px;

// Color Variables
$black: #000;
$white: #fff;
$text-grey: #9d9d9d;

$heading-text-color: #171717;
$paragraph-text-color: rgba(#171717, 0.8);
//$main-brand-color: #cc3333;
$main-brand-color: #f00;
$secondary-color: #f0f0f2;

$gray-900: #27292c;

$fp-gray-100: #27292c;

//Broadsheet Colors

$body-text: #666;
$header-text: #2a2a2a;
$details-color: #10b2b2;

body * {
  color: $body-text;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $header-text;
}

$header-height: 90.5px;

//// Product Variables
//@import '_shared/@fp-design/themes/_shared/variables';
//
//// Overrides
//$body-text: purple;
//$font-family: 'nunito', sans-serif;
//
//$container-width: 900px;
//
//// SVE Variables
//
//
//
////// Example
//body {
//  background-color: blue;
//}

// Base Imports
@import '../themes/base/reset';
@import '../themes/nkr-files/colors';
@import '../themes/base/typography';
@import '../themes/layout/spacing';

// Helpers Imports
@import 'nkr-files/variables';
@import '../themes/helpers/functions';
@import '../themes/helpers/breakpoints';
@import '../themes/helpers/mixins';

// Layout Imports
@import '../themes/layout/grid';
@import '../themes/layout/spacing';
@import '../themes/layout/page-layout';

// Import Editor CSS
@import 'editor/editor';

// Import Shell
@import 'components/Shell';

// Component Imports
@import 'components/article-info';
@import 'components/article-body';
@import 'components/teaser';
@import 'components/list';
@import 'components/blockquote';
@import 'components/image';
@import 'components/header';
@import 'nkr-files/header';
@import 'nkr-files/footer';
@import 'nkr-files/teasers-nkr';
@import 'components/sidemenu';
@import 'components/button';
@import 'components/searchPage';
@import 'components/ads';
@import 'components/paywall';
@import 'nkr-files/paywall';
@import 'components/region-header';
@import 'components/ressort-page';
@import 'components/embed';
@import 'components/slide';

@import '../components/SectionHeader/SectionHeader';
@import '../components/SveArticleInfo/SveArticleInfo';
@import '../components/SveArticleInfo/Articles';
@import '../components/SvePageContainer/SvePageContainer';
@import '../components/SveRelatedArticles/SveRelatedArticles';
@import '../components/SvePopularGroup/SvePopularTeaser';
@import '../templates/ArticleTeaser';
@import '../components/SveEmbedCode/SveEmbedCode';
@import '../components/SvePopularGroup/SvePopularGroup';
@import '../components/SveRegionLinks/SveRegionLinks';
@import '../components/SveCta/SveCta';
@import '../components/SveContributorInfo/SveContributorInfo';
@import '../components/SveTopicHeader/SveTopicHeaderInfo';
@import '../components/SveRelatedTopics/SveRelatedTopics';
@import '../components/SveAdvertorialTeaser/SveAdvertorialTeaser';

// tailwind theme
@import '../../dist/themes/theme-nkr.css';

.fp-header .fp-btn_link {
  border-radius: 50px;
  color: $on-primary;
  background-color: c(primary-40);
}

.editor-container {
  margin: 0 auto;
  max-width: 1000px;
  padding: 16px 0;
  width: 100%;
}

//FIX ME iva
.fp-advertisement {
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
  background: #f4f4f4;
  padding: 10px;
  margin-bottom: 1rem;
  overflow: hidden;
}
