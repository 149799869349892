// This file is a reference point for all the FOOTER
// It is imported in the index.scss file

//fixme IIS
.fp-footer > .fp-grid-container {
  padding: 0;
}

.fp-footer {
  background-color: c(neutral-variant-95);

  > section {
    @include size(100%, 150px);
  }

  &-top {
    padding: 2.5rem 1rem 1.875rem;

    @include media-breakpoint-down(sm) {
      padding-top: 2.25rem;
    }

    &--logo {
      text-align: center;

      a {
        display: block;
        font-size: 0;
      }

      svg {
        width: 100%;
        height: 100%;
        max-width: 168px;
      }
    }

    &--rights {
      font-weight: 600;
      line-height: 2;

      small {
        font-size: 0.875rem;
      }
    }
  }

  .sve-social-media {
    @include flex(center, $g: 0 $spacer-xxl);
    margin: 0;
  }

  &-top--wrapper {
    @include flex(center, center, $spacer-xxl + $spacer-lg);
    margin-bottom: 2.5rem;
    gap: 6.625rem;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      gap: $spacer-base;
      margin-bottom: 2.25rem;
    }
  }

  &-top--nav {
    @include flex(space-between, flex-start, 66px);
    border-top: 1px solid c(neutral-90);
    padding-top: 2.5rem;
    max-width: 796px;
    margin: auto;

    @include media-breakpoint-down(sm) {
      align-items: flex-start;
      gap: 30px;
      flex-direction: column;
      padding-top: 0;
      border-top: 0;
    }

    ul {
      @include flex(flex-start, space-between, $wrap: wrap, $dir: column);
      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
    }

    .fp-footer-title {
      font-size: $text-md;
      font-weight: 700;
      margin-bottom: 0.625rem;
      line-height: 1.3rem;
      margin-top: 44px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 0.1875rem;
        margin-top: 30px;
      }
    }

    li {
      font-size: 0.875rem;
      line-height: 1.8;

      @include media-breakpoint-down(sm) {
        font-size: $text-md;
      }

      a {
        color: $black;
      }

      &:first-child {
        font-size: $text-md;
        font-weight: 700;
        margin-bottom: 0.625rem;
        line-height: 1.3rem;

        @include media-breakpoint-down(sm) {
          margin-bottom: 0.1875rem;
        }
      }
    }
  }
}
